import clients from "./clients";

export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Overview',
    route: 'dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'Triggers',
    route: 'triggers',
    icon: 'ZapIcon',
  },
  {
    title: 'Access Control',
    route: 'access-control',
    icon: 'UnlockIcon',
  },
  {
    title: 'D2Scale AI',
    route: 'access-control',
    icon: 'UnlockIcon',
  },
  ...clients
]
